<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  name="First Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="First Name" label-for="mc-name">
                    <b-form-input
                      id="mc-name"
                      placeholder="Fisrt Name"
                      v-model="model.firstName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Last Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Last Name" label-for="mc-name">
                    <b-form-input
                      id="mc-name"
                      placeholder="Last Name"
                      v-model="model.lastName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Email Address"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Email Address" label-for="mc-name">
                    <b-form-input
                      id="mc-email"
                      placeholder="Email Address"
                      v-model="model.username"
                      :readonly="$route.meta.action == 'edit'"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Mobile Phone"
                  :rules="{
                    regex: /^(\+62|62|0)8[1-9][0-9]{6,11}$/,
                    required: true,
                  }"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Mobile Phone" label-for="mc-no-telepon">
                    <b-form-input
                      id="mc-no-telepon"
                      placeholder="Mobile Phone"
                      v-model="model.mobilePhone"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- <b-col md="6">
                <ValidationProvider
                  name="Tipe Pertanyaan"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Tipe Pertanyaan" label-for="mc-type">
                    <b-form-radio-group
                      v-model="model.type"
                      :options="typeOptions"
                      class="mt-1"
                      value-field="value"
                      text-field="text"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col> -->
              <b-col md="12">
                <ValidationProvider
                  name="Image Profile"
                  :rules="
                    $route.meta.action == 'store'
                      ? 'required|ext:jpeg,png,jpg'
                      : 'ext:jpeg,png,jpg'
                  "
                  v-slot="{ errors }"
                >
                  <b-form-group label="Image Profile" label-for="mc-icon">
                    <b-form-file
                      v-model="ava"
                      @change="avatar_change"
                      placeholder="Choose a file..."
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row> </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'user' }"
              >
                Cancel
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}

.ql-container {
  height: 200px !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import { password } from "@/@core/utils/validations/validations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      errors: {},
      showDismissibleAlert: false,
      ava: null,
      config: {
        api: "users",
        redirect: "user",
      },
      model: {
        username: "",
        profile: {
          avatar: "",
        },
      },
      messages: "",
      roles: [],
    };
  },
  mounted() {
    let _ = this;
    _.getData();
  },
  methods: {
    avatar_change(e) {
      let vm = this;
      // this.model.avatar = e.target.files[0];
      this.model.avatar = e.target.files[0].name;
      console.log(this.model.avatar);
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data;
            _.model.lastName = _.model.profile.lastName;
            _.model.firstName = _.model.profile.firstName;
            _.model.mobilePhone = _.model.profile.mobilePhone;
            _.model.avatar = _.model.profile.avatar;
          });
      }
    },
    save() {
      let _ = this;
      _.model.id = _.$route.params.id;
      let data_post = {
        profile: {
          firstName: _.model.firstName,
          lastName: _.model.lastName,
          mobilePhone: _.model.mobilePhone,
          avatar: _.model.avatar,
        },
      };
      console.log(data_post);
      axios
        .patch(_.config.api + "/" + _.$route.params.id, data_post)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              event: "success",
              title: "Update Success",
              text: "Data has been Updated",
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
        });
    },
  },
};
</script>
